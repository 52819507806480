body {
    font-family: 'Courier New', Courier, monospace;
}

.selfie {
    max-height: 500px;

}

.selfie-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.header-text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.selfie-text {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 18px;
    font-family: 'Courier New', Courier, monospace;
}

.body-css {
    margin: 0 auto;

    justify-content: center;
    max-width: 1000px;
    border-bottom: 2px solid #000;
    border-left: 2px solid #000;
    border-right: 2px solid #000;

}