.cv-header {
    max-width: 800px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
}

.cv-container {

    max-width: 1000px;

}

.cv-text {
    .container {
        display: flex;
        justify-content: space-between;
    }
}

.cv-text-left {
    padding-left: 10px;
    text-align: left;
}

.cv-text-right {
    text-align: right;
    padding-right: 10px;
}