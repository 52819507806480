.footer {
    font-family: serif;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-style: italic;
}

.instagram {
    max-width: 25px;
    max-height: 25px;
    padding-right: 20px;
}

.facebook {
    max-width: 25px;
    max-height: 25px;
}

.linkedin {
    max-width: 25px;
    max-height: 25px;
}